import React, { useContext, useEffect, useState, useRef } from "react";
// import { connect } from 'react-redux';

import ShopContext from "Utilities/shop-context";

// import { removeProductFromCart } from '../store/actions';

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardContent from "@material-ui/core/CardContent";
import { CardMedia } from "@material-ui/core";
import { CurrencyContext } from "Utilities/currencyContext";
import { axiosClient } from "Utilities/axiosConfig";
import AuthContext from "Utilities/AuthContext";
import Button from "components/CustomButtons/Button";

import { useHistory, useParams } from "react-router-dom";
import GridSpinner from "components/LoaderComponent/Grid";

import PrintIcon from "@material-ui/icons/Print";
import IconButton from "@material-ui/core/IconButton";

import { useReactToPrint } from "react-to-print";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardTitleBlack: {
    color: "black",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  root: {
    display: "flex",
    justifyContent: "center",
  },
  details: {
    display: "flex",
    flexDirection: "row",
  },
  content: {
    flex: "1 0 auto",
    width: "fit-content",
  },
  cover: {
    width: "100%",
    height: "100%",
  },
  footer: {
    justifyContent: "space-between",
  },
  gridolian: {
    flexGrow: 1,
  },
  paper: {
    padding: 10,
    textAlign: "center",
    color: "textSecondary",
    justifyContent: "space-between",
  },
  row: {
    padding: 10,
    textAlign: "center",
    color: "textSecondary",
    justifyContent: "space-between",
  },

  button: {
    width: "100%",
  },
  media: {
    width: 160,
    height: 160,
  },
};

const useStyles = makeStyles(styles);

const OrderViewPage = () => {
  const classes = useStyles();
  const context = useContext(ShopContext);
  const { returnValue, returnSpecificFormatted } = useContext(CurrencyContext);
  const { user } = useContext(AuthContext);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [received, setReceived] = useState(null);
  const [beforePrint, setBeforePrint] = useState(false);
  const [confirmPressed, setConfirmPresed] = useState(false);
  let history = useHistory();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: () => "html, body { direction: rtl; }",
    onAfterPrint: async (e) => {
      console.log(e);
      handleBeforePrint();
    },
  });

  const handleBeforePrint = () => {
    setBeforePrint(!beforePrint);
  };

  const handleOrder = () => {
    var orderFromLocalStorage = JSON.parse(
      localStorage.getItem("al-shamkh_order_id")
    );
    getData(orderFromLocalStorage);
    localStorage.removeItem("al-shamkh_order_id");
  };

  const confirmOrder = async (id) => {
    setConfirmPresed(!confirmPressed);
    let headers = {
      Accept: "application/json",
    };
    try {
      const res = await axiosClient.post("/orders/confirm/" + id, headers);
      console.log(res);
      //setCurrentOrder(res.order);
      setConfirmPresed(!confirmPressed);
      getData(currentOrder.id);
    } catch (err) {
      console.log(err);
      setConfirmPresed(!confirmPressed);
    }
  };

  const receiveOrder = async (id) => {
    let headers = {
      Accept: "application/json",
    };
    let payload = {
      received: received,
      order_id: id,
    };
    try {
      const res = await axiosClient.post("/orders/received/" + id, payload, {
        headres: headers,
      });
      console.log(res);
      getData(currentOrder.id);
    } catch (err) {
      console.log(err);
    }
  };

  const completeOrder = async (id) => {
    let headers = {
      Accept: "application/json",
    };
    try {
      const res = await axiosClient.post("/orders/complete/" + id, headers);
      console.log(res.order);
      getData(currentOrder.id);
    } catch (err) {
      console.log(err);
    }
  };

  const cancelOrder = async (id) => {
    let headers = {
      Accept: "application/json",
    };
    try {
      const res = await axiosClient.post("/orders/cancel/" + id, headers);
      console.log(res.order);
      getData(currentOrder.id);
    } catch (err) {
      console.log(err);
    }
  };

  const transactionsArray = (t) => {
    console.log("transactions array ran");
    let arr = [];
    t.forEach((t) => {
      arr.push([t.received, t.remaining, t.created_at]);
    });
    setTransactions(arr);
  };

  const getData = async (orderID) => {
    let headers = {
      Accept: "application/json",
    };
    try {
      const res = await axiosClient.get("/orders/" + orderID, headers);
      console.log("order: ", res.order);
      setCurrentOrder(res.order);
      transactionsArray(res.order.transactions);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleOrder();
  }, []);

  if (!currentOrder || !transactions) return <GridSpinner />;

  return (
    <Grid container alignItems="center" className={classes.root}>
      <Grid item xs={12} sm={12} md={8}>
        <Button
          color="warning"
          onClick={() => {
            history.push("/rtl/orders");
          }}
          type="submit"
        >
          الرجوع الى الطلبات
        </Button>
        <Card>
          <div ref={componentRef}>
            <CardHeader color="success">
              <h3
                className={
                  beforePrint ? classes.cardTitleBlack : classes.cardTitleWhite
                }
              >{`طلب رقم ${currentOrder.id}`}</h3>
              <h3
                className={
                  beforePrint ? classes.cardTitleBlack : classes.cardTitleWhite
                }
              >{`اسم المتجر:  ${currentOrder.user_trademark}`}</h3>
              <h3
                className={
                  beforePrint ? classes.cardTitleBlack : classes.cardTitleWhite
                }
              >{`صاحب المتجر:  ${currentOrder.user_name}`}</h3>
              <h3
                className={
                  beforePrint ? classes.cardTitleBlack : classes.cardTitleWhite
                }
              >{`رقم الهاتف:   ${currentOrder.user_phone}`}</h3>
              <h3
                className={
                  beforePrint ? classes.cardTitleBlack : classes.cardTitleWhite
                }
              >{`الحالة :   ${
                currentOrder.status == 1
                  ? "قيد التاكيد"
                  : currentOrder.status == 2
                  ? "قيد التوصيل"
                  : currentOrder.status == 3
                  ? "مكتمل"
                  : null
              }`}</h3>
            </CardHeader>
            <CardBody>
              {currentOrder.new_items.length > 0
                ? currentOrder.new_items.map((p) => {
                    return (
                      <Grid item xs={12} sm={12} md={12}>
                        <Card style={{ backgroundColor: "#c9c9c9" }}>
                          {/* <Grid container> */}
                          {/* <Grid item xs={12} sm={12} md={12}>
                          <div className={classes.details}> */}

                          <CardContent
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography component="h5" variant="h5">
                                {p.name}
                              </Typography>
                              {!beforePrint ? (
                                <Typography
                                  variant="subtitle1"
                                  color="textSecondary"
                                >
                                  {returnValue(p.price)}
                                </Typography>
                              ) : null}
                            </div>

                            <CardMedia
                              className={classes.media}
                              image={p.image}
                            />
                          </CardContent>

                          {/* </div>
                        </Grid> */}
                          {/* </Grid> */}
                          <CardFooter className={classes.footer}>
                            <span style={{ fontSize: 20, fontWeight: "bold" }}>
                              {p.quantity}
                            </span>
                            {!beforePrint ? (
                              <span
                                style={{
                                  fontSize: 20,
                                  fontWeight: "bold",
                                  marginRight: "auto",
                                }}
                              >
                                {returnValue(p.price * p.quantity)}
                              </span>
                            ) : null}
                          </CardFooter>
                        </Card>
                      </Grid>
                    );
                  })
                : null}
              <Card className={classes.root}>
                <CardHeader color="primary">
                  <h4
                    className={
                      beforePrint
                        ? classes.cardTitleBlack
                        : classes.cardTitleWhite
                    }
                  >
                    معلومات المستلم
                  </h4>
                </CardHeader>
                <Grid container>
                  <Grid item xs={8} sm={8} md={8}>
                    <div className={classes.details}>
                      <CardContent className={classes.content}>
                        <Typography color="textSecondary" variant="h6">
                          الاسم
                        </Typography>
                        <Typography variant="h5">
                          {currentOrder.receiver.name}
                        </Typography>
                        <Typography color="textSecondary" variant="h6">
                          رقم الهاتف
                        </Typography>
                        <Typography variant="h5">
                          {currentOrder.receiver.phone}
                        </Typography>
                        <Typography color="textSecondary" variant="h6">
                          العنوان
                        </Typography>
                        <Typography variant="h5">
                          {currentOrder.receiver.address}
                        </Typography>
                        <Typography color="textSecondary" variant="h6">
                          المبلغ
                        </Typography>
                        <Typography variant="h5">
                          {returnSpecificFormatted(
                            currentOrder.receiver.cost,
                            1
                          )}
                        </Typography>
                        <Typography color="textSecondary" variant="h6">
                          سعر التوصيل
                        </Typography>
                        <Typography variant="h5">
                          {returnSpecificFormatted(
                            currentOrder.receiver.delivery,
                            1
                          )}
                        </Typography>
                        <Typography variant="h6" color="textSecondary">
                          الملاحظات
                        </Typography>
                        <Typography variant="h5">
                          {currentOrder.receiver.note}
                        </Typography>
                      </CardContent>
                    </div>
                  </Grid>
                </Grid>
                {/*  */}
              </Card>

              {/* <Card style={{ display: beforePrint ? "none" : "block" }}>
                <CardHeader color="warning">
                  <h3 className={classes.cardTitleWhite}>الدفعات</h3>
                </CardHeader>
                <CardBody>
                  <Table
                    tableHeaderColor="primary"
                    tableHead={["مبلغ الدفعة", "المبلغ الباقي", "التاريخ"]}
                    tableData={transactions}
                  />
                </CardBody>
              </Card> */}
            </CardBody>
            {/* {beforePrint ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <h3 className={classes.cardTitleBlack}>المجموع</h3>
                <div
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                >
                  {returnValue(currentOrder.total_usd)}
                </div>
              </div>
            ) : null} */}
          </div>

          <div className={classes.root}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <div className={classes.row}>
                  <div
                    style={{
                      fontSize: 20,
                      fontWeight: "bold",
                      // marginRight: "auto",
                    }}
                  >
                    {returnSpecificFormatted(currentOrder.total_iqd, 1)}
                  </div>
                </div>
              </Grid>
              <Grid xs={6}>
                <div className={classes.row}>
                  <IconButton
                    variant="filled"
                    color={beforePrint ? "secondary" : "primary"}
                    onClick={() => {
                      if (beforePrint) {
                        handleBeforePrint();
                      } else {
                        handleBeforePrint();
                        setTimeout(handlePrint, 3000);
                      }
                    }}
                  >
                    <PrintIcon />
                  </IconButton>
                </div>
              </Grid>
              {currentOrder.status == 1 ? (
                <>
                  <Grid item xs={6}>
                    <div className={classes.row}>
                      <Button
                        color="warning"
                        onClick={() => confirmOrder(currentOrder.id)}
                        type="submit"
                        className={classes.button}
                        disabled={confirmPressed ? true : false}
                      >
                        تاكيد
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes.row}>
                      <Button
                        variant="contained"
                        color="danger"
                        component="span"
                        onClick={() => {
                          cancelOrder(currentOrder.id);
                        }}
                        className={classes.button}
                      >
                        الغاء
                      </Button>
                    </div>
                  </Grid>
                </>
              ) : null}
              {currentOrder.status == 2 ? (
                <>
                  <Grid item xs={6}>
                    <div className={classes.row}>
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        onClick={() => completeOrder(currentOrder.id)}
                        type="submit"
                        className={classes.button}
                      >
                        انهاء الطلب
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes.row}>
                      <Button
                        variant="contained"
                        color="danger"
                        component="span"
                        onClick={() => {
                          cancelOrder(currentOrder.id);
                        }}
                        className={classes.button}
                      >
                        الغاء
                      </Button>
                    </div>
                  </Grid>
                </>
              ) : null}
            </Grid>
          </div>
        </Card>
      </Grid>
    </Grid>
  );
};

export default OrderViewPage;

// </Grid container xs={12} sm={12} md={12} alignItems="center">
//               <Grid item xs={12} sm={12} md={6}>
//                 <div
//                   style={{
//                     fontSize: 20,
//                     fontWeight: "bold",
//                     // marginRight: "auto",
//                   }}
//                 >
//                   {returnValue(currentOrder.total_usd)}
//                 </div>
//               </Grid>
//               <Grid item xs={12} sm={12} md={6}>

//               </Grid>
//             </Grid>
//             {currentOrder.status == 1 ? (

//             ) : currentOrder.status == 2 ? (
//               <Grid
//                 container
//                 xs={12}
//                 sm={12}
//                 md={12}
//                 style={{ alignItems: "center", justifyContent: "center" }}
//               >
//                 <Grid item xs={12} sm={12} md={4}>
//                   <Button
//                     color="success"
//                     onClick={() => receiveOrder(currentOrder.id)}
//                     type="submit"
//                   >
//                     استلام مبلغ
//                   </Button>
//                 </Grid>
//                 <Grid item xs={12} sm={12} md={8}>
//                   <TextField
//                     id="outlined-full-width"
//                     label={"المبلغ المستلم"}
//                     style={{ margin: 8 }}
//                     placeholder={"المبلغ المستلم"}
//                     fullWidth
//                     margin="normal"
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     variant="outlined"
//                     onChange={(e) => setReceived(e.target.value)}
//                     type="text"
//                   />
//                 </Grid>
//               </Grid>

//             ) : null}

// <Grid item xs={12}>
//                     <div className={classes.row}>
//                       <TextField
//                         id="outlined-full-width"
//                         label={"المبلغ المستلم"}
//                         style={{ margin: 8, width: "90%" }}
//                         placeholder={"المبلغ المستلم"}
//                         margin="dense"
//                         InputLabelProps={{
//                           shrink: true,
//                         }}
//                         variant="filled"
//                         onChange={(e) => setReceived(e.target.value)}
//                         type="text"
//                       />
//                     </div>
//                   </Grid>

import React, { useContext, useEffect, useState } from "react";
// import { connect } from 'react-redux';

import ShopContext from "Utilities/shop-context";

// import { removeProductFromCart } from '../store/actions';

import { makeStyles } from "@material-ui/core/styles";
import { FormGroup, FormControlLabel } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardContent from "@material-ui/core/CardContent";
import { Avatar, TextField } from "@material-ui/core";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import RemoveCircleRoundedIcon from "@material-ui/icons/RemoveCircleRounded";
import IconButton from "@material-ui/core/IconButton";
import { CurrencyContext } from "Utilities/currencyContext";
import { axiosClient } from "Utilities/axiosConfig";
import AuthContext from "Utilities/AuthContext";
import Button from "components/CustomButtons/Button";
import { Checkbox } from "@material-ui/core";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  root: {
    display: "flex",

    justifyContent: "center",
  },
  details: {
    display: "flex",
    flexDirection: "row",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: "100%",
    height: "100%",
  },
  footer: {
    justifyContent: "space-between",
  },
  gridolian: {
    flexGrow: 1,
  },
};

const useStyles = makeStyles(styles);

const CartPage = () => {
  const classes = useStyles();
  const context = useContext(ShopContext);
  const { returnValue, returnSpecific, returnUsdExplicit } = useContext(
    CurrencyContext
  );
  const { user } = useContext(AuthContext);
  const [receiver, setReceiver] = useState({
    name: "" || "غير مذكور",
    phone: "",
    address: "",
    cost: 0,
    usd_cost: 0,
    delivery: 0,
    delivery_usd: 0,
    note: null,
  });

  const [iqdChecked, setIqdChecked] = useState(true);
  const [usdChecked, setUsdChecked] = useState(false);
  const [freeDelivery, setFreeDelivery] = useState(false);

  const [confirmPressed, setConfirmPresed] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [deliveryInvalid, setDeliveryInvalid] = useState(false);
  const calculateTotal = () => {
    let total = 0;
    context.cart.forEach((p) => {
      total += p.price * p.quantity;
    });
    return total;
  };

  useEffect(() => {
    console.log(context);
  }, []);

  const submitOrder = async () => {
    setConfirmPresed(!confirmPressed);
    var order_items = [];
    context.cart.forEach((p) => {
      var obj = {
        order: 0,
        product_id: p.id,
        name: p.title,
        price: p.price,
        total_usd: returnSpecific(p.price),
        quantity: p.quantity,
        total_iqd: returnSpecific(p.price, 1),
      };
      order_items.push(obj);
    });
    let payload = {
      user_id: user.user.id,
      user_name: user.user.name_ar,
      user_phone: user.user.phone,
      total_usd: returnSpecific(calculateTotal()),
      total_iqd: returnSpecific(calculateTotal(), 1),
      status: 1,
      items: order_items,
      receiver: receiver,
    };

    try {
      const headers = {
        Accept: "application/json",
      };

      const res = await axiosClient.post("/orders", payload, {
        headers: headers,
      });
      console.log(res);
      alert("Success!");
      context.clearCart();
      setConfirmPresed(!confirmPressed);
    } catch (err) {
      console.log(err);
      alert("Error, please try again");
      setConfirmPresed(!confirmPressed);
    }
  };

  return (
    <Grid container alignItems="center" className={classes.root}>
      <Grid item xs={12} sm={12} md={8}>
        <Card>
          <form>
            <CardHeader color="success">
              <h3 className={classes.cardTitleWhite}>عربة التسوق</h3>
            </CardHeader>
            <CardBody>
              {context.cart.length > 0 ? (
                context.cart.map((p) => {
                  return (
                    <Grid item xs={12} sm={12} md={12}>
                      <Card className={classes.root}>
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12}>
                            <div className={classes.details}>
                              <CardContent className={classes.content}>
                                <Typography component="h5" variant="h5">
                                  {p.title}
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  color="textSecondary"
                                >
                                  {returnValue(p.price)}
                                </Typography>
                              </CardContent>
                            </div>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4}>
                            <Avatar
                              className={classes.cover}
                              variant={"rounded"}
                              src={p.image}
                            />
                          </Grid>
                        </Grid>
                        <CardFooter className={classes.footer}>
                          <IconButton
                            aria-label="add"
                            onClick={() => {
                              context.addProductToCart(p);
                            }}
                          >
                            <AddCircleRoundedIcon />
                          </IconButton>
                          <span style={{ fontSize: 20, fontWeight: "bold" }}>
                            {p.quantity}
                          </span>
                          <IconButton
                            aria-label="remove"
                            onClick={() => {
                              context.removeProductFromCart(p.id);
                            }}
                          >
                            <RemoveCircleRoundedIcon />
                          </IconButton>
                          <span
                            style={{
                              fontSize: 20,
                              fontWeight: "bold",
                              marginRight: "auto",
                            }}
                          >
                            {returnValue(p.price * p.quantity)}
                          </span>
                        </CardFooter>
                      </Card>
                    </Grid>
                  );
                })
              ) : (
                <h1>لا يوجد منتجات</h1>
              )}
              {context.cart.length > 0 ? (
                <>
                  <Grid xs={12} sm={12} md={12}>
                    <TextField
                      id="outlined-full-width"
                      label={"اسم الزبون"}
                      style={{ margin: 8 }}
                      placeholder={"اسم الزبون"}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="filled"
                      onChange={(e) => {
                        setReceiver((r) => {
                          return { ...r, name: e.target.value };
                        });
                      }}
                    />
                  </Grid>
                  <Grid xs={12} sm={12} md={12}>
                    <TextField
                      required
                      id="outlined-full-width"
                      label={"رقم الهاتف"}
                      style={{ margin: 8 }}
                      placeholder={"رقم الهاتف"}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="filled"
                      onChange={(e) => {
                        setReceiver((r) => {
                          return { ...r, phone: e.target.value };
                        });
                      }}
                    />
                  </Grid>
                  <Grid xs={12} sm={12} md={12}>
                    <TextField
                      required
                      id="outlined-multiline-static"
                      label="العنوان"
                      multiline
                      variant="filled"
                      rows={4}
                      fullWidth
                      onChange={(e) => {
                        setReceiver((r) => {
                          return { ...r, address: e.target.value };
                        });
                      }}
                    />
                  </Grid>
                  <Grid xs={12} sm={12} md={12} style={{ marginTop: 5 }}>
                    <TextField
                      id="outlined-multiline-static"
                      label="ملاحظات عن الطلب (اختياري)"
                      multiline
                      variant="filled"
                      rows={3}
                      fullWidth
                      onChange={(e) => {
                        setReceiver((r) => {
                          return { ...r, note: e.target.value };
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Typography
                      color={"textPrimary"}
                      variant={"h6"}
                      style={{ marginTop: 5 }}
                    >
                      يرجى ادخال المبالغ بالدينار العراقي
                    </Typography>
                    {/* <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={iqdChecked}
                            onChange={() => {
                              setIqdChecked(!iqdChecked);
                              setUsdChecked(!usdChecked);
                            }}
                          />
                        }
                        label=" IQD دينار"
                        style={{ color: "black", fontWeight: "bold" }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() => {
                              setIqdChecked(!iqdChecked);
                              setUsdChecked(!usdChecked);
                            }}
                            checked={usdChecked}
                          />
                        }
                        label=" USD دولار"
                        style={{ color: "black", fontWeight: "bold" }}
                      />
                    </FormGroup> */}
                  </Grid>
                  <Grid xs={12} sm={12} md={12}>
                    {invalid ? (
                      <Typography variant="h6" color="error">
                        {`يجب ان يكون المبلغ يساوي او اكثر من المجموع بالدينار العراقي (${parseInt(
                          returnSpecific(calculateTotal(), 1)
                        )})`}
                      </Typography>
                    ) : null}
                    <TextField
                      required
                      id="outlined-full-width"
                      label={"المبلغ"}
                      style={{ margin: 8 }}
                      placeholder={"المبلغ"}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="filled"
                      onChange={(e) => {
                        let iqd_value, usd_value;
                        if (iqdChecked) {
                          iqd_value = e.target.value;
                          usd_value = returnUsdExplicit(e.target.value);
                          if (
                            e.target.value <
                            parseInt(returnSpecific(calculateTotal(), 1))
                          ) {
                            setInvalid(true);
                          } else {
                            setInvalid(false);
                          }
                        } else {
                          iqd_value = returnSpecific(e.target.value, 1);
                          usd_value = e.target.value;
                          if (
                            e.target.value < returnSpecific(calculateTotal())
                          ) {
                            setInvalid(true);
                          } else {
                            setInvalid(false);
                          }
                        }
                        setReceiver((r) => {
                          return {
                            ...r,
                            cost: iqd_value,
                            usd_cost: iqd_value,
                          };
                        });
                        console.log([
                          "cost: " + iqd_value,
                          "USD cost: " + usd_value,
                        ]);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={8}>
                    {deliveryInvalid ? (
                      <Typography variant="h6" color="error">
                        سعر التوصيل يجب ان يكون 1000 دينار او اكثر
                      </Typography>
                    ) : null}
                    <TextField
                      required
                      id="outlined-full-width"
                      label={"سعر التوصيل"}
                      style={{ margin: 8 }}
                      placeholder={"سعر التوصيل"}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="filled"
                      onChange={(e) => {
                        let iqd_value, usd_value;
                        if (iqdChecked) {
                          iqd_value = e.target.value;
                          usd_value = returnUsdExplicit(e.target.value);
                          if (e.target.value < 1000) {
                            setDeliveryInvalid(true);
                          } else {
                            setDeliveryInvalid(false);
                          }
                        } else {
                          iqd_value = returnSpecific(e.target.value, 1);
                          usd_value = e.target.value;
                          if (e.target.value < 1000) {
                            setDeliveryInvalid(true);
                          } else {
                            setDeliveryInvalid(false);
                          }
                        }
                        setReceiver((r) => {
                          return {
                            ...r,
                            delivery: iqd_value,
                            delivery_usd: usd_value,
                          };
                        });
                        console.log([
                          "delivery cost: " + iqd_value,
                          "delivery USD cost: " + usd_value,
                        ]);
                      }}
                      disabled={freeDelivery ? true : false}
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            setFreeDelivery(!freeDelivery);
                            if (e.target.checked) {
                              setDeliveryInvalid(false);
                            } else if (
                              !e.target.checked &&
                              receiver.delivery < 1000
                            ) {
                              setDeliveryInvalid(true);
                            }
                          }}
                          checked={freeDelivery}
                        />
                      }
                      label="التوصيل مجاني؟"
                      style={{ color: "black", fontWeight: "bold" }}
                    />
                  </Grid>
                </>
              ) : null}
            </CardBody>
            <CardFooter style={{ justifyContent: "space-between" }}>
              <Button
                color="success"
                onClick={(e) => {
                  if (context.cart.length > 0) {
                    if (e.currentTarget.form.reportValidity()) {
                      submitOrder();
                    }
                  } else {
                    alert("العربة فارغة!");
                  }
                }}
                disabled={
                  confirmPressed || invalid || deliveryInvalid ? true : false
                }
                //type="submit"
              >
                تاكيد
              </Button>
              {
                <span
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    // marginRight: "auto",
                  }}
                >
                  {returnValue(calculateTotal())}
                </span>
              }
            </CardFooter>
          </form>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CartPage;

/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
// react plugin for creating charts

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components

// import GridItem from "components/Grid/GridItem.js";
// import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import {
  Card as Kard,
  CardMedia as KardMedia,
  CardActionArea as KardActionArea,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";

import styles from "assets/jss/material-dashboard-react/views/rtlStyle.js";

//rat imports
import { axiosClient } from "../Utilities/axiosConfig";
import Button from "components/CustomButtons/Button";
import { useHistory } from "react-router-dom";
import OrderViewPage from "./OrderViewPage";
import GridSpinner from "components/LoaderComponent/Grid";
import { CurrencyContext } from "Utilities/currencyContext";

const useStyles = makeStyles(styles);

export default function PaymentsPage() {
  const classes = useStyles();
  const { returnSpecificFormatted } = useContext(CurrencyContext);
  let history = useHistory();

  const [user, setUser] = useState(null);
  const [transactions, setTransactions] = useState();

  const handleUser = () => {
    var userFromLocalStorage = JSON.parse(
      localStorage.getItem("al-shamkh_user_id")
    );
    refreshUser(userFromLocalStorage);
    localStorage.removeItem("al-shamkh_user_id");
  };

  const refreshUser = async (id) => {
    const headers = {
      Accept: "application/json",
    };
    try {
      const res = await axiosClient.get("/payments/" + id, headers);

      setUser({ id: res.id, name: res.name });
      let arr = [];
      res.transactions.map((t) => {
        arr.push([returnSpecificFormatted(t.received, 1), t.created_at]);
      });
      setTransactions(arr);

      console.log("Payments Page: ", res.name);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleUser();
  }, []);

  if (!transactions) return <GridSpinner />;

  return (
    <Grid
      container
      spacing={1}
      alignItems="center"
      style={{
        display: "flex",

        justifyContent: "center",
        paddingLeft: 0,
        paddingRight: 0,
      }}
    >
      <Grid item xs={12} sm={12} md={10}>
        <Button
          color="warning"
          onClick={() => {
            localStorage.setItem("al-shamkh_user_id", JSON.stringify(user.id));

            setTimeout(() => {
              history.push("/rtl/userview");
            }, 700);
          }}
        >
          الرجوع الى المستخدم
        </Button>
        <Card>
          <CardHeader color="warning">
            <h3 className={classes.cardTitleWhite}>الدفعات المستلمة</h3>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={["مبلغ الدفعة", "التاريخ"]}
              tableData={transactions}
            />
          </CardBody>
        </Card>
      </Grid>
    </Grid>
  );
}

/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import ArrowUpward from "@material-ui/icons/ArrowUpward";

import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
import NewReleasesIcon from "@material-ui/icons/NewReleases";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
// core components
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { Typography } from "@material-ui/core";

import styles from "assets/jss/material-dashboard-react/views/rtlStyle.js";

import { Oval } from "react-loader-spinner";
import GridSpinner from "components/LoaderComponent/Grid";
import { axiosClient } from "Utilities/axiosConfig";
import AuthContext from "Utilities/AuthContext";
import { useHistory } from "react-router-dom";
import { CurrencyContext } from "Utilities/currencyContext";
import HorizontalProductCard from "components/HorizontalProductCard";
import OvalSpinner from "components/LoaderComponent/oval";
import PaymentModal from "components/PaymentModal";
const useStyles = makeStyles(styles);

export default function RTLPage() {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  let history = useHistory();
  const { returnValue, returnSpecificFormatted } = useContext(CurrencyContext);

  const [orders, setOrders] = useState(null);
  const [latestProducts, setLatestProducts] = useState();
  const [profits, setProfits] = useState(null);
  const [userDues, setUserDues] = useState(null);
  const [userBalance, setUserBalance] = useState(null);
  const [requiredItems, setRequiredItems] = useState(null);

  const ViewOrderButton = (order) => {
    return (
      <Button
        color="primary"
        onClick={() => {
          localStorage.setItem("al-shamkh_order_id", JSON.stringify(order.id));

          setTimeout(() => {
            history.push(
              user.user.role == 1 ? "/rtl/orderview" : "/rtl/orderviewuser"
            );
          }, 300);
        }}
      >
        عرض
      </Button>
    );
  };

  const modalToggler = (id, balance) => {
    return (
      <PaymentModal userId={id} balance={balance} refresh={populateHome} />
    );
  };

  const goToDueButton = (id) => {
    return <Button color="primary">عرض</Button>;
  };

  const populateHome = async () => {
    try {
      const res = await axiosClient.get(
        user.user.role == 1
          ? "/adminHome/" + user.user.id
          : "/home/" + user.user.id
      );

      setUserBalance(res.balance);

      let unconfirmed = [];
      let confirmed = [];
      let complete = [];
      //mustafa needed to know what items to provide and how many of each
      let unconfirmedItems = [];
      let reqItems = [];
      //
      let profitsArr = [];
      let dues = [];
      console.log("HOME API RESPONSE: ", res);
      res.orders.unconfirmed.map((o, i) => {
        unconfirmed.push([
          o.id,
          `${returnSpecificFormatted(o.total_iqd, 1)} `,
          o.status == 1 ? "قيد التاكيد" : "قيد التوصيل",
          ViewOrderButton(o),
        ]);
        o.items.map((item) => {
          const found = unconfirmedItems.some((i) => i.name === item.name);
          if (!found) {
            unconfirmedItems.push({
              id: item.product_id,
              name: item.name,
              quantity: item.quantity,
            });
          } else {
            let foundItem = unconfirmedItems.find((i) => i.name === item.name);
            foundItem.quantity += item.quantity;
          }
        });
      });
      res.orders.confirmed.map((o) => {
        confirmed.push([
          o.id,
          `${returnSpecificFormatted(o.total_iqd, 1)} `,
          o.status == 1 ? "قيد التاكيد" : "قيد التوصيل",
          ViewOrderButton(o),
        ]);
      });
      res.orders.complete.map((o) => {
        complete.push([
          o.id,
          `${returnSpecificFormatted(o.total_iqd, 1)} `,
          o.status == 1
            ? "قيد التاكيد"
            : o.status == 2
            ? "قيد التوصيل"
            : "كامل",
          ViewOrderButton(o),
        ]);
      });

      if (user.user.role == 2) {
        console.log("executed profit mapper function");
        setUserBalance(res.balance);
        res.profits.map((p) => {
          profitsArr.push([
            p.id,
            returnSpecificFormatted(p.profit, 1),
            p.created_at,
            ViewOrderButton(p),
          ]);
        });
      }

      if (user.user.role == 1) {
        res.user_dues.map((d) => {
          dues.push([
            d.id,
            d.name_ar,
            returnSpecificFormatted(d.balance, 1),
            modalToggler(d.id, d.balance),
          ]);
        });
      }

      //rquired items
      unconfirmedItems.map((item) => {
        reqItems.push([item.name, item.quantity]);
      });
      setRequiredItems(reqItems);
      //

      setOrders({ unconfirmed, confirmed, complete });
      setLatestProducts(res.products);
      setProfits(profitsArr);
      setUserDues(dues);
      console.log("orders Array: ", orders);
      console.log("unconfirmed items: ", unconfirmedItems);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (user) {
      populateHome();
      console.log(user);
    }

    return () => {
      setOrders(null);
      setLatestProducts(null);
      setProfits(null);
      setUserDues(null);
      setUserBalance(null);
    };
  }, []);

  if (orders == null || latestProducts == null || userDues == null)
    return <GridSpinner />;

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>المبيعات</p>
              <h3 className={classes.cardTitle}>
                {returnSpecificFormatted(userBalance, 1)}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>مجموع ارباح كل مبيعاتك</div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h3 className="kufi" style={{ marginBottom: "8px", fontWeight: 500 }}>
            اخر المنتجات
          </h3>
        </GridItem>

        {latestProducts !== null
          ? latestProducts.map((p) => (
              <GridItem xs={12} sm={12} md={6}>
                <HorizontalProductCard product={p} />
              </GridItem>
            ))
          : null}
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <CustomTabs
            title={<h4 className={classes.cardTitleWhite}>الطلبات</h4>}
            headerColor="primary"
            tabs={[
              {
                tabName: "قيد التأكيد",
                tabIcon: NewReleasesIcon,
                tabContent: (
                  <Table
                    tableHeaderColor="primary"
                    tableHead={["رقم الطلب", " المجموع", "الحالة ", "-"]}
                    tableData={orders.unconfirmed}
                    dense
                  />
                ),
              },
              {
                tabName: "قيد التوصيل",
                tabIcon: CheckCircleIcon,
                tabContent: (
                  <Table
                    tableHeaderColor="primary"
                    tableHead={["رقم الطلب", " المجموع", "الحالة ", "-"]}
                    tableData={orders.confirmed}
                    dense
                  />
                ),
              },
              {
                tabName: "مكتملة",
                tabIcon: Cloud,
                tabContent: (
                  <Table
                    tableHeaderColor="primary"
                    tableHead={["رقم الطلب", " المجموع", "الحالة ", "-"]}
                    tableData={orders.complete}
                    dense
                  />
                ),
              },
            ]}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          {user.user.role == 1 ? (
            <>
              <CustomTabs
                title={<h4 className={classes.cardTitleWhite}>المستحقات</h4>}
                headerColor={"danger"}
                tabs={[
                  {
                    tabName: "ارباح مستحقة",
                    tabIcon: SupervisorAccountIcon,
                    tabContent: (
                      <>
                        <Table
                          tableHeaderColor="danger"
                          tableHead={["ID ", " الاسم", "المستحق ", "-"]}
                          tableData={userDues}
                          dense
                        />
                        <Button
                          color="primary"
                          onClick={() => {
                            setTimeout(() => {
                              history.push("/rtl/userdues");
                            }, 300);
                          }}
                          style={{ marginTop: 12 }}
                        >
                          عرض الكل
                        </Button>
                      </>
                    ),
                  },
                  {
                    tabName: "التجهيزات",
                    tabIcon: SupervisorAccountIcon,
                    tabContent: (
                      <>
                        <Table
                          tableHeaderColor="danger"
                          tableHead={[" الاسم", "الكمية "]}
                          tableData={requiredItems}
                          dense
                        />
                      </>
                    ),
                  },
                ]}
              />

              <CardFooter></CardFooter>
            </>
          ) : (
            <Card>
              <CardHeader color="success">
                <h3 className={classes.cardTitleWhite}>المبيعات</h3>
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="warning"
                  tableHead={["ID", "Amount", "Date", "-"]}
                  tableData={profits}
                  dense
                />
              </CardBody>
              <CardFooter>
                <Button
                  color="primary"
                  onClick={() => {
                    setTimeout(() => {
                      history.push("/rtl/userprofits");
                    }, 300);
                  }}
                  styles
                >
                  عرض الكل
                </Button>
              </CardFooter>
            </Card>
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
}

// {      <GridContainer>
//   <GridItem xs={12} sm={12} md={4}>
//     <Card chart>
//       <CardHeader color="success">
//         <ChartistGraph
//           className="ct-chart"
//           data={dailySalesChart.data}
//           type="Line"
//           options={dailySalesChart.options}
//           listener={dailySalesChart.animation}
//         />
//       </CardHeader>
//       <CardBody>
//         <h4 className={classes.cardTitle}>Daily Sales</h4>
//         <p className={classes.cardCategory}>
//           <span className={classes.successText}>
//             <ArrowUpward className={classes.upArrowCardCategory} /> 55%
//           </span>
//           increase in today sales.
//         </p>
//       </CardBody>
//       <CardFooter chart>
//         <div className={classes.stats}>
//           <AccessTime /> updated 4 minutes ago
//         </div>
//       </CardFooter>
//     </Card>
//   </GridItem>
//   <GridItem xs={12} sm={12} md={4}>
//     <Card chart>
//       <CardHeader color="warning">
//         <ChartistGraph
//           className="ct-chart"
//           data={emailsSubscriptionChart.data}
//           type="Bar"
//           options={emailsSubscriptionChart.options}
//           responsiveOptions={emailsSubscriptionChart.responsiveOptions}
//           listener={emailsSubscriptionChart.animation}
//         />
//       </CardHeader>
//       <CardBody>
//         <h4 className={classes.cardTitle}>Email Subscriptions</h4>
//         <p className={classes.cardCategory}>Last Campaign Performance</p>
//       </CardBody>
//       <CardFooter chart>
//         <div className={classes.stats}>
//           <AccessTime /> campaign sent 2 days ago
//         </div>
//       </CardFooter>
//     </Card>
//   </GridItem>
//   <GridItem xs={12} sm={12} md={4}>
//     <Card chart>
//       <CardHeader color="danger">
//         <ChartistGraph
//           className="ct-chart"
//           data={completedTasksChart.data}
//           type="Line"
//           options={completedTasksChart.options}
//           listener={completedTasksChart.animation}
//         />
//       </CardHeader>
//       <CardBody>
//         <h4 className={classes.cardTitle}>Completed Tasks</h4>
//         <p className={classes.cardCategory}>Last Campaign Performance</p>
//       </CardBody>
//       <CardFooter chart>
//         <div className={classes.stats}>
//           <AccessTime /> campaign sent 2 days ago
//         </div>
//       </CardFooter>
//     </Card>
//   </GridItem>
// </GridContainer>}

// card to use for revenue and shit like it
// {<GridItem xs={12} sm={6} md={3}>
// <Card>
//   <CardHeader color="warning" stats icon>
//     <CardIcon color="warning">
//       <Icon>content_copy</Icon>
//     </CardIcon>
//     <p className={classes.cardCategory}>Used Space</p>
//     <h3 className={classes.cardTitle}>
//       49/50 <small>GB</small>
//     </h3>
//   </CardHeader>
//   <CardFooter stats>
//     <div className={classes.stats}>
//       <Danger>
//         <Warning />
//       </Danger>
//       <a href="#pablo" onClick={(e) => e.preventDefault()}>
//         Get more space
//       </a>
//     </div>
//   </CardFooter>
// </Card>
// </GridItem>}

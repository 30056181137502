import React, { useContext, useEffect, useState } from "react";
// import { connect } from 'react-redux';

import ShopContext from "Utilities/shop-context";

// import { removeProductFromCart } from '../store/actions';

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardContent from "@material-ui/core/CardContent";
import { CurrencyContext } from "Utilities/currencyContext";
import { axiosClient } from "Utilities/axiosConfig";
import AuthContext from "Utilities/AuthContext";
import Button from "components/CustomButtons/Button";

import GridSpinner from "components/LoaderComponent/Grid";
import { useHistory } from "react-router-dom";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  root: {
    display: "flex",

    justifyContent: "center",
  },
  details: {
    display: "flex",
    flexDirection: "row",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: "100%",
    height: "100%",
  },
  footer: {
    justifyContent: "space-between",
  },
  gridolian: {
    flexGrow: 1,
  },
};

const useStyles = makeStyles(styles);

const OrderViewPageUser = ({ unset }) => {
  const classes = useStyles();
  const context = useContext(ShopContext);
  const { returnValue, returnSpecific, returnSpecificFormatted } = useContext(
    CurrencyContext
  );
  const { user } = useContext(AuthContext);
  const [currentOrder, setCurrentOrder] = useState(null);
  let history = useHistory();

  const getData = async (orderID) => {
    try {
      const res = await axiosClient.get("/orders/" + orderID);
      setCurrentOrder(res.order);
      localStorage.removeItem("al-shamkh_order_id");
      console.log(res.order);
    } catch (err) {
      console.log(err);
    }
  };

  const handleOrder = async () => {
    let localStorageOrderId = localStorage.getItem("al-shamkh_order_id");
    getData(localStorageOrderId);
  };

  useEffect(() => {
    handleOrder();
  }, []);

  if (!currentOrder) return <GridSpinner />;

  return (
    <Grid container alignItems="center" className={classes.root}>
      <Grid item xs={12} sm={12} md={8}>
        <Button
          color="warning"
          onClick={() => {
            history.push("/rtl/orders");
          }}
          type="submit"
        >
          الرجوع الى الطلبات
        </Button>
        <Card>
          <CardHeader color="success">
            <h3
              className={classes.cardTitleWhite}
            >{`طلب رقم ${currentOrder.id}`}</h3>
            <h3 className={classes.cardTitleWhite}>{`الحالة :   ${
              currentOrder.status == 1
                ? "قيد التاكيد"
                : currentOrder.status == 2
                ? "قيد التوصيل"
                : currentOrder.status == 3
                ? "مكتمل"
                : null
            }`}</h3>
            {/* <h3
              className={classes.cardTitleWhite}
            >{`رقم الهاتف:   ${currentOrder.user_phone}`}</h3> */}
          </CardHeader>
          <CardBody>
            {currentOrder.items.length > 0 ? (
              currentOrder.items.map((p) => {
                return (
                  <Grid item xs={12} sm={12} md={12}>
                    <Card className={classes.root}>
                      {/* <Grid container> */}
                      {/* <Grid item xs={12} sm={12} md={12}>
                          <div className={classes.details}> */}
                      <CardContent className={classes.content}>
                        <Typography component="h5" variant="h5">
                          {p.name}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                          {returnValue(p.price)}
                        </Typography>
                      </CardContent>
                      {/* </div>
                        </Grid> */}
                      {/* </Grid> */}
                      <CardFooter className={classes.footer}>
                        <span style={{ fontSize: 20, fontWeight: "bold" }}>
                          {p.quantity}
                        </span>

                        <span
                          style={{
                            fontSize: 20,
                            fontWeight: "bold",
                            marginRight: "auto",
                          }}
                        >
                          {returnValue(p.price * p.quantity)}
                        </span>
                      </CardFooter>
                    </Card>
                  </Grid>
                );
              })
            ) : (
              <h1>لا يوجد طلبات</h1>
            )}
            <Card className={classes.root}>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>معلومات المستلم</h4>
              </CardHeader>
              <Grid container>
                <Grid item xs={8} sm={8} md={8}>
                  <div className={classes.details}>
                    <CardContent className={classes.content}>
                      <Typography color="textSecondary" variant="h6">
                        الاسم
                      </Typography>
                      <Typography variant="h5">
                        {currentOrder.receiver.name}
                      </Typography>
                      <Typography color="textSecondary" variant="h6">
                        رقم الهاتف
                      </Typography>
                      <Typography variant="h5">
                        {currentOrder.receiver.phone}
                      </Typography>
                      <Typography color="textSecondary" variant="h6">
                        العنوان
                      </Typography>
                      <Typography variant="h5">
                        {currentOrder.receiver.address}
                      </Typography>
                      <Typography color="textSecondary" variant="h6">
                        المبلغ
                      </Typography>
                      <Typography variant="h5">
                        {returnSpecificFormatted(currentOrder.receiver.cost, 1)}
                      </Typography>
                      <Typography color="textSecondary" variant="h6">
                        سعر التوصيل
                      </Typography>
                      <Typography variant="h5">
                        {returnSpecificFormatted(
                          currentOrder.receiver.delivery,
                          1
                        )}
                      </Typography>
                      <Typography variant="h6" color="textSecondary">
                        الملاحضات
                      </Typography>
                      <Typography variant="h5">
                        {currentOrder.receiver.note}
                      </Typography>
                    </CardContent>
                  </div>
                </Grid>
              </Grid>
              {/*  */}
            </Card>
            {currentOrder.status > 1 ? (
              <Card>
                <CardHeader color={"rose"}>
                  <h4 className={classes.cardTitleWhite}>الربح من الطلب</h4>
                </CardHeader>
                <CardBody>
                  <Typography variant="h5" color="rose">
                    {returnSpecificFormatted(currentOrder.profit_user, 1)}
                  </Typography>
                </CardBody>
              </Card>
            ) : null}
          </CardBody>
          <CardFooter>
            {/* <Button color="success" onClick={() => submitOrder()} type="submit">
              تاكيد
            </Button> */}
            {
              <span
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  // marginRight: "auto",
                }}
              >
                {"مبلغ البضاعة: " +
                  returnSpecificFormatted(currentOrder.total_iqd, 1)}
              </span>
            }
          </CardFooter>
        </Card>
      </Grid>
    </Grid>
  );
};

export default OrderViewPageUser;

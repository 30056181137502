import React, { useContext, useEffect, useState } from "react";
// import { connect } from 'react-redux';

import ShopContext from "Utilities/shop-context";

// import { removeProductFromCart } from '../store/actions';

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardContent from "@material-ui/core/CardContent";
import { CurrencyContext } from "Utilities/currencyContext";
import { axiosClient } from "Utilities/axiosConfig";
import AuthContext from "Utilities/AuthContext";
import Button from "components/CustomButtons/Button";
import GridSpinner from "components/LoaderComponent/Grid";
import Table from "components/Table/Table";
import { useHistory } from "react-router-dom";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  root: {
    display: "flex",

    justifyContent: "center",
  },
  details: {
    display: "flex",
    flexDirection: "row",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: "100%",
    height: "100%",
  },
  footer: {
    justifyContent: "space-between",
  },
  gridolian: {
    flexGrow: 1,
  },
};

const useStyles = makeStyles(styles);

const SupplierViewPage = ({}) => {
  const classes = useStyles();
  const context = useContext(ShopContext);
  const { returnValue, returnSpecific } = useContext(CurrencyContext);
  const { user } = useContext(AuthContext);
  const [currentSupplier, setCurrentSupplier] = useState(null);
  // const [isLoading, setIsLoading] = useState(false);
  const [restocks, setRestocks] = useState(null);
  let history = useHistory();

  const handleSupplier = () => {
    var orderFromLocalStorage = JSON.parse(
      localStorage.getItem("al-shamkh_supplier_id")
    );
    getData(orderFromLocalStorage);
    localStorage.removeItem("al-shamkh_supplier_id");
  };

  const ViewRestockButton = (restock) => {
    return (
      <Button
        color="primary"
        onClick={() => {
          localStorage.setItem(
            "al-shamkh_restock_id",
            JSON.stringify(restock.id)
          );

          setTimeout(() => {
            history.push("/rtl/restockview");
          }, 300);
        }}
      >
        عرض
      </Button>
    );
  };

  const getData = async (id) => {
    let headers = {
      Accept: "application/json",
    };
    try {
      let arr = [];
      const res = await axiosClient.get("/suppliers/" + id, headers);

      res.restocks.map((r) => {
        arr.push([
          r.id,
          r.supplier_name,
          returnValue(r.total),
          ViewRestockButton(r),
        ]);
      });
      setCurrentSupplier(res.supplier);
      setRestocks(arr);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleSupplier();
  }, []);

  if (currentSupplier == null || restocks == null) return <GridSpinner />;
  return (
    <Grid container alignItems="center" className={classes.root}>
      <Grid item xs={12} sm={12} md={8}>
        <Button
          color="warning"
          onClick={() => {
            history.push("/rtl/suppliers");
          }}
          type="submit"
        >
          الرجوع الى الطلبات
        </Button>
        <Card>
          <CardHeader color="success">
            <h3
              className={classes.cardTitleWhite}
            >{`${currentSupplier.trademark}`}</h3>
            <h3
              className={classes.cardTitleWhite}
            >{`${currentSupplier.name}`}</h3>
            <h3
              className={classes.cardTitleWhite}
            >{`رقم الهاتف:   ${currentSupplier.phone}`}</h3>
            {/* <h3 className={classes.cardTitleWhite}>{`الحالة :   ${
              currentSupplier.status == 1 ? "قيد التاكيد" : "قيد التوصيل"
            }`}</h3> */}
          </CardHeader>
          <CardBody>
            {restocks.length > 0 ? (
              <Table
                tableHeaderColor="primary"
                tableHead={["رقم الطلب", " اسم المورد", "المجموغ ", "-"]}
                tableData={restocks}
                dense
              />
            ) : (
              "No Restocks to display."
            )}
          </CardBody>
        </Card>
      </Grid>
    </Grid>
  );
};

export default SupplierViewPage;
